import React, { Component } from "react";
import "./NavBar.css";

class Navbar extends Component {
  render() {
    return <nav className="NavbarItems">{/* Empty space */}</nav>;
  }
}

export default Navbar;
